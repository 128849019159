//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ReviewsPermissions } from '@/modules/reviews/reviews-permissions';
import ReviewsListCard from '@/modules/reviews/components/reviews-list-card.vue';
import { i18n } from '@/i18n';

export default {
  name: 'app-reviews-list-page',

  components: {
    [ReviewsListCard.name]: ReviewsListCard,
  },

  data() {
    return {
      totalRating: null,
      //#region 
      // reviews: [
      //   {
      //     rate: 3.5,
      //     description: {
      //       en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //       ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //     },
      //     client: {
      //       firstName: 'Ahmed',
      //       fullName: 'Ahmed Mostafa',
      //       avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
      //     },
      //     createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     createdAt: '2021-02-08T20:59:08.103Z',
      //     updatedAt: '2021-02-08T20:59:08.103Z',
      //   },
      //   {
      //     rate: 3.5,
      //     description: {
      //       en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //       ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //     },
      //     client: {
      //       firstName: 'Ahmed',
      //       fullName: 'Ahmed Mostafa',
      //       avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
      //     },
      //     createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     createdAt: '2021-02-08T20:59:08.103Z',
      //     updatedAt: '2021-02-08T20:59:08.103Z',
      //   },
      //   {
      //     rate: 3.5,
      //     description: {
      //       en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //       ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //     },
      //     client: {
      //       firstName: 'Ahmed',
      //       fullName: 'Ahmed Mostafa',
      //       avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
      //     },
      //     createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     createdAt: '2021-02-08T20:59:08.103Z',
      //     updatedAt: '2021-02-08T20:59:08.103Z',
      //   },
      //   {
      //     rate: 3.5,
      //     description: {
      //       en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //       ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //     },
      //     client: {
      //       firstName: 'Ahmed',
      //       fullName: 'Ahmed Mostafa',
      //       avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
      //     },
      //     createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     createdAt: '2021-02-08T20:59:08.103Z',
      //     updatedAt: '2021-02-08T20:59:08.103Z',
      //   },
      //   {
      //     rate: 3.5,
      //     description: {
      //       en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //       ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //     },
      //     client: {
      //       firstName: 'Ahmed',
      //       fullName: 'Ahmed Mostafa',
      //       avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
      //     },
      //     createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     createdAt: '2021-02-08T20:59:08.103Z',
      //     updatedAt: '2021-02-08T20:59:08.103Z',
      //   },
      //   {
      //     rate: 3.5,
      //     description: {
      //       en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //       ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //     },
      //     client: {
      //       firstName: 'Ahmed',
      //       fullName: 'Ahmed Mostafa',
      //       avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
      //     },
      //     createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     createdAt: '2021-02-08T20:59:08.103Z',
      //     updatedAt: '2021-02-08T20:59:08.103Z',
      //   },
      //   {
      //     rate: 3.5,
      //     description: {
      //       en: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //       ar: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla.',
      //     },
      //     client: {
      //       firstName: 'Ahmed',
      //       fullName: 'Ahmed Mostafa',
      //       avatar: 'https://cdn.quasar.dev/img/avatar2.jpg',
      //     },
      //     createdBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     updatedBy: 'cef94fbed27e7e4e7fb33a97084d2c1f',
      //     createdAt: '2021-02-08T20:59:08.103Z',
      //     updatedAt: '2021-02-08T20:59:08.103Z',
      //   },
      // ],
      //#endregion
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      companyRecord: 'company/view/record',
      loading: 'reviews/list/loading'
    }),
    hasPermissionToCreate() {
      return new ReviewsPermissions(this.currentUser).create;
    },
  },
  methods: {
    i18n(key, args) {
      return i18n(key, args);
      // return this.$t(key, args);
    },
    ...mapActions({
      doFetch: 'reviews/list/doFetch',
      doFind: 'company/view/doFind'
    })
  },
  async created(){
    // debugger
    await this.doFetch({userId:this.currentUser.companyId, type:'company'})
    await this.doFind(this.currentUser.companyId)
    console.log(this.companyRecord);
    this.totalRating = this.companyRecord.rate
  }
};
