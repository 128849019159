import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
import IntegerField from '@/shared/fields/integer-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import StringArrayField from '@/shared/fields/string-array-field';
import BooleanField from '@/shared/fields/boolean-field';
import JsonField from '@/shared/fields/json-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
// import ImagesField from '@/shared/fields/images-field';
// import i18n from './vueI18n'

function label(name) {
  return i18n(`entities.reviews.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.reviews.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  name: new JsonField('name', label('name')),
  description: new JsonField('description', label('description')),
  startCityId: new StringField('startCityId', label('startCityId'), {}),
  startCity: new StringField('startCity', label('startCity'), {}),
  type: new EnumeratorField('type', label('type'), [
    { value: 'default', label: enumeratorLabel('reviewsType', 'default') },
    { value: 'custom', label: enumeratorLabel('reviewsType', 'custom') },
  ]),
  // numberOfDays: new IntegerField('numberOfDays', label('numberOfDays'), {}),
  numberOfDays: new EnumeratorField('numberOfDays', label('numberOfDays'), [
    { value: 2, label: i18n('entities.reviews.tripDays.twoDays') },
    { value: 3, label: i18n('entities.reviews.tripDays.ThreeDays') },
    { value: 4, label: i18n('entities.reviews.tripDays.FourDays') },
    { value: 5, label: i18n('entities.reviews.tripDays.FiveDays') },
    { value: 6, label: i18n('entities.reviews.tripDays.SixDays') },
    { value: 7, label: i18n('entities.reviews.tripDays.SevenDays') },
  ]),
  disabled: new BooleanField('disabled', label('disabled'), {
    noLabel: i18n('user.enabled'),
    yesLabel: i18n('user.disabled'),
  }),
  programs: new StringArrayField('programs', label('programs'), {}),


  //#region []
    numberOfReviewss: new IntegerField('numberOfReviewss', label('numberOfReviewss'), {}),
    cityName: new StringArrayField('cityName', label('cityName'), {}),
  //#endregion

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class ReviewsModel extends GenericModel {
  static get fields() {
    return fields;
  }
}