//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ReviewsPermissions } from '@/modules/reviews/reviews-permissions';
import ReviewCard from '@/modules/reviews/components/review-card.vue';
// import { i18n } from '@/i18n';

export default {
  name: 'app-reviews-list-card',
  props: {
     
  },

  components: {
    [ReviewCard.name]: ReviewCard,
  },

  data() {
    return {
      current: 3,
      page: 1
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
      rows: 'reviews/list/rows',
      loading: 'reviews/list/loading',
      isFirstPage: 'reviews/list/isFirstPage',
      isLastPage: 'reviews/list/isLastPage'
    }),
    hasPermissionToCreate() {
      return new ReviewsPermissions(this.currentUser).create;
    },

    getItemsPerPage() {
      // const { screen } = this.$q;
      //   if (screen.lt.sm) {
      //       return 4;
      //   }
      //   if (screen.lt.md) {
      //       return 6;
      //   }
      //   if (screen.lt.lg) {
      //       return 9;
      //   }
      //   if (screen.lt.xl) {
      //       return 9;
      //   }
        return 10;
        // return 5
    },
  },
  methods: {
    ...mapActions({
      // doChangeSort: 'iam/list/doChangeSort',
      // doChangePaginationCurrentPage: 'iam/list/doChangePaginationCurrentPage',
      // doChangePaginationPageSize: 'iam/list/doChangePaginationPageSize',
      // doMountTable: 'iam/list/doMountTable',
      doFetch: 'reviews/list/doFetch'
    }),
    i18n(key, args) {
      // return i18n(key, args);
      return this.$t(key, args);
    },
    async goToPreviousPage(){
      console.log('go to previous page');
      console.log('prev');
      const orderBy = 'createdAt'
      const pagination = {
          sortBy: "desc",
          limit: this.getItemsPerPage,
          action: "prev",
          page: this.page
      }
      await this.doFetch({
          userId: this.currentUser.companyId, 
          type: "company",
          filter:{},
          orderBy,
          pagination
      })
      this.page -= 1
    },
    async loadMore(){
       const orderBy = 'createdAt'
        const pagination = {
            sortBy: "desc",
            limit: this.getItemsPerPage,
            action: "next",
            page: this.page
        }
        await this.doFetch({
          userId: this.currentUser.companyId, 
          type: "company",
          filter:{},
          orderBy,
          pagination
        })
        this.page += 1
    }
  },
  async mounted(){
    // console.log(this.currentUser.companyId);
    // debugger
    await this.doFetch({
      userId: this.currentUser.companyId, 
      type: "company",
      filter: {}, 
      orderBy: "createdAt",
      pagination:{
        sortBy: "desc",
        limit: this.getItemsPerPage
        // limit: 5
      }})
  }
};
