//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { ReviewsPermissions } from '@/modules/reviews/reviews-permissions';
import { i18n, getLanguageCode } from '@/i18n';
import moment from 'moment';

import { ReviewsModel } from '@/modules/reviews/reviews-model';

import TripViewModal from '@/modules/trip/components/trip-view-modal.vue';

// import ReviewsViewModal from '@/modules/reviews/components/reviews-view-modal.vue';

// import { FormSchema } from '@/shared/form/form-schema';
const { fields } = ReviewsModel;

export default {
  name: 'app-review-card',
  props: {
    value: {
    } 
  },

  components: {
    [TripViewModal.name]: TripViewModal,

    // [ReviewsViewModal.name]: ReviewsViewModal,
  },
  data() {
    return {
      language: getLanguageCode(),
      selectedID: null,
      dialogVisible: false,
      modalVisible: false,
      statusLoading: false,
      
      model: null,
      places: [],
      placesName: [],
      images: [],
      isDisableReviews: false,
    }
  },
  computed: {
    ...mapGetters({
      // record: 'reviews/view/record',
      // loading: 'reviews/view/loading',
      // destroyLoading: 'reviews/destroy/loading',
      // changeStatusLoading: 'reviews/form/changeStatusLoading',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    hasPermissionToEdit() {
      return new ReviewsPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new ReviewsPermissions(this.currentUser).destroy;
    },
    // displayPlaces() {
    //   return this.placesName.slice(0, 3);
    // },
    // displayImages() {
    //   return this.images.slice(0, 3);
    // },
    // displayRecord() {
    //   return {
    //     ...this.value,
    //     places: this.places,
    //     images: this.images,
    //   }
    // },
    // startPoint() {
    //   return this.value.startCity ? this.value.startCity.name[this.currentLanguageCode] : 'ــــ'
    // },
    // endPoint() {
    //   const lastPlaceIndex = this.placesName.length - 1
    //   const lastPlace = this.placesName[lastPlaceIndex]
    //   return lastPlace ? lastPlace[this.currentLanguageCode] : 'ــــ'
    // },
  },

  // mounted() {
  //   this.value.programs.forEach(program => {
  //     program.places.forEach(place => {
  //       if (place.selectedImage) this.images.push(place.selectedImage)
  //       if (place.place) this.placesName.push(place.place.placeName)
  //       if (place.place) this.places.push({ ...place.place, averageTime: place.averageTime })
  //     })
  //   });
  // },
  methods: {
    ...mapActions({
      // doFind: 'reviews/view/doFind',
      // doDestroy: 'reviews/destroy/doDestroy',
      // doDisable: 'reviews/form/doDisable',
      // doEnable: 'reviews/form/doEnable',
    }),
    i18n(code, args) {
      return i18n(code, args);
    },
    presenter(row, fieldName) {
      return ReviewsModel.presenter(row, fieldName);
    },
    presenterMap(row, fieldName) {
      const val = ReviewsModel.presenter(row, fieldName);
      return val[this.currentLanguageCode]
    },
    presenterDate(fieldName) {
      // return moment(ReviewsModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
      return moment(fieldName).locale(this.currentLanguageCode).format("D MMM, YYYY"); 
    },
    presenterDay(fieldName) {
      // return moment(ReviewsModel.presenter(row, fieldName)).locale(this.currentLanguageCode).format("dddd");
      return moment(fieldName).locale(this.currentLanguageCode).format("dddd");
    },
    presenterTime(row, fieldName) {
      let now = moment().format('YYYY/MM/DD');
      let time = ReviewsModel.presenter(row, fieldName)
      let date =  `${now} ${time}`;
      return moment(date).locale(this.currentLanguageCode).format("hh:mm a"); 
    },
    onOpenModal() {
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
    // async doDestroyWithConfirm(id) {
    //   try {
    //     await this.doDestroy(id);
    //     this.onModalClose();
    //   } catch (error) {
    //     // no
    //   }
    // },
    // async doDisableReviews() {
    //   this.statusLoading = true
    //   const id = this.value.id
    //   await this.doDisable(id)
    //   this.value.disabled = true
    //   this.statusLoading = false
    // },
    // async doEnableReviews() {
    //   this.statusLoading = true
    //   const id = this.value.id
    //   await this.doEnable(id)
    //   this.value.disabled = false
    //   this.statusLoading = false
    // },
  },
};
