import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/iam/permission-checker';

export class ReviewsPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(
      currentUser,
    );

    this.read = permissionChecker.match(
      Permissions.values.reviewsRead,
    );
    this.import = permissionChecker.match(
      Permissions.values.reviewsImport,
    );
    this.reviewsAutocomplete = permissionChecker.match(
      Permissions.values.reviewsAutocomplete,
    );
    this.create = permissionChecker.match(
      Permissions.values.reviewsCreate,
    );
    this.edit = permissionChecker.match(
      Permissions.values.reviewsEdit,
    );
    this.destroy = permissionChecker.match(
      Permissions.values.reviewsDestroy,
    );
  }
}
